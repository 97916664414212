.page {
  background-color: $primary-light;
  padding: 0;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  @include respond-to(phone) {
    background-color: white;
    padding: 0 0 4rem 0;
  }

  app-loader {
    z-index: 1;
  }

  @at-root #{&}__header {
    display: flex;
    flex-direction: column;
    background-color: $primary;
    background-image: url("assets/img/header.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    // background-image: $gradient-theme;
    z-index: 2;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    padding: 3rem 2rem;
    margin: 1rem;
    margin-bottom: 0;
    font-weight: normal;
    text-transform: uppercase;
    flex-shrink: 0;
    user-select: none;
    border-radius: $border-radius $border-radius 0 0;

    @include respond-to(phone) {
      margin: 0;
      height: auto;
      border-radius: 0;
      font-size: 1.5rem;
      padding: 2rem;
    }

    @include respond-to(tablet) {
      padding: 2rem;
    }

    @at-root #{&}__back {
      @extend .row;
      justify-content: flex-start;
      padding: 0;
      color: $primary-light;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 1rem;

      .material-icons {
        font-size: 0.8em;
      }
    }

    @at-root #{&}__title {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .material-icons {
        margin-right: 1rem;
        // margin-top: 2px;
        font-size: 1.8rem;

        @include respond-to(phone) {
          margin-top: -2px;
          font-size: 2rem;
        }
      }

      input {
        background-color: transparent !important;
        color: $white;
        font-size: 1.6rem;
        line-height: 1.6rem;
        border-bottom: 1px solid transparent;
        width: 45%;
        padding: 0.5rem;
      }

      input:focus:not(:read-only) {
        border-color: $white;
      }

      ::placeholder {
        color: $white;
        font-weight: normal;
        font-size: 1.6rem;
      }
    }

    @at-root #{&}__tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 1.5rem;

      @include respond-to(phone) {
        overflow-x: auto;
        padding: 0.2rem;
        padding-top: 1.5rem;
      }

      a {
        font-size: 1.1rem;
        padding: 0.6rem 1rem;
        margin-right: 1rem;
        text-transform: capitalize;
        border-radius: $border-radius;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
        min-width: 8rem;

        @include respond-to(phone) {
          max-width: 8rem;
          font-size: 1.2rem;
          min-width: 0;
        }

        span {
          @extend .ellipsis;

          @include respond-to(phone) {
            display: none;
          }

          &:only-child {
            margin-left: 0;

            @include respond-to(phone) {
              display: block;
            }
          }
        }

        .material-icons {
          font-size: 1.2em;

          @include respond-to(phone) {
            font-size: 1.7em;
          }
        }

        img {
          width: 1em;

          @include respond-to(phone) {
            width: 1.5em;
          }
        }

        &.selected {
          background-image: linear-gradient(
            0,
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.3)
          );
        }
      }
    }
  }

  @at-root #{&}__toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 1rem;
    padding: 1rem 2rem;
    background-color: $black;
    box-shadow: $box-shadow;
    border-radius: 0 0 $border-radius $border-radius;
    // height: 6rem;

    @include respond-to(phone) {
      margin: 0;
      border-radius: 0;
      padding: 0.8rem 2rem;
    }

    @include respond-to(tablet) {
      padding: 0.8rem 2rem;
    }

    // app-loader {
    //   flex-grow: 0;
    //   padding: 0;
    // }

    button {
      color: $white;
      font-size: 1.1rem;

      &:hover {
        background-color: $black-light !important;
      }
    }

    .text-button {
      background-color: transparent;
      box-shadow: none;

      @include respond-to(phone) {
        font-size: 1.3rem;
      }
    }

    .search {
      color: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1rem;
      font-size: 1rem;

      .material-icons {
        font-size: 1.5em;
      }

      .material-icons.cancel {
        font-size: 1em;
      }

      input {
        background-color: transparent;
        border-bottom: 1.2px solid $white;
        line-height: 1rem;
        color: $white;
        padding: 0.2rem;
        border-radius: 0;
        margin-left: 0.5rem;
      }
    }

    .status {
      padding: 0 1rem;
    }
  }

  @at-root #{&}__content {
    margin: 1rem;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    position: relative;
    min-height: 0;

    @include respond-to(phone) {
      margin: 0;
      width: 100%;
    }
  }
}

.page.darkmode {
  background-color: $black-dark;
}
