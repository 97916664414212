@import "variables";

body,
html {
	background-color: transparent;
}

.emoji {
	margin-left: calc(50vw - 169px);
}

.primary {
	color: var(--themeColor, $primary) !important;
}
