@import "variables";

.dark-theme {
  ::-webkit-scrollbar-thumb {
    background-color: $black-light;
  }

  .mat-expansion-panel,
  .mat-expansion-panel-header,
  .mat-expansion-panel-body,
  .auth__wrapper,
  .chatform-editor,
  .message__action .text-button:hover:not(:disabled),
  html,
  body,
  .mat-drawer-container,
  .page,
  .privacy {
    background-color: $black-dark;
  }

  .auth__wrapper {
    @include respond-to(phone) {
      background-color: $black-darker;
    }
  }

  .page__header__tab a.selected,
  .page__toolbar {
    box-shadow: $box-shadow;
    z-index: 0;
  }

  #app-nav-bottom {
    box-shadow: $box-shadow-dark;
  }

  .chatroom__info,
  .mat-expansion-panel.chatform-editor__item,
  .chatform-editor__item,
  .chatform,
  #app-nav-bottom,
  .mat-drawer,
  .mat-table,
  .chatroom-preview,
  .chatroom__input,
  .chatroom__content,
  .sidebar,
  .task,
  .auth,
  .setting,
  .dashboard__chart,
  .knowledge__item,
  .chat-item,
  .empty-placeholder {
    background-color: $black !important;
  }

  .message .user-icon {
    background-image: none;
  }

  .auth__form {
    background-color: $black-darker;

    .language {
      color: $white;
    }

    .submit {
      background-color: $black;
      color: $white;
    }

    .auth__form__row,
    .oauth button {
      background-color: $black;
      color: $white;
    }
  }

  .auth__form__row:focus-within {
    border-left: 0.5rem solid $black;
  }

  .inbox__chatlist,
  .inbox__chatlist .chat-item {
    border-color: $black;
  }

  .inbox__chatlist .chat-item.active {
    border-color: $primary;

    @include respond-to(phone) {
      border-color: $black;
    }
  }

  .message__text,
  .message__attachment__item {
    background-color: $black-light;
    background-image: none;
    color: $white;
  }

  .message__timestamp {
    color: $white80;
  }

  .right {
    .message__text,
    .message__attachment__item {
      background-color: $primary;
    }
  }

  .message__attachment .message__timestamp .info {
    color: $white;
  }

  .chatroom__info {
    @include respond-to(phone) {
      background-color: $primary !important;
    }
  }

  .chatroom__input,
  .chatroom__info,
  .sidebar__logo,
  .sidebar__collapse,
  .sidebar__user {
    border-color: $black-dark;
  }

  .preview {
    .chatroom__input,
    .chatroom__info {
      border-color: $primary-light;
    }

    .chatroom__input,
    .chatroom__content {
      background-color: $white;
    }

    .message__text {
      background-color: $primary-light;
      background-image: $gradient-light;
      color: $font-color;
    }
  }

  .chatform-editor__item,
  .mat-expansion-panel-header,
  .attachment__item a,
  .sidebar__menu__item,
  .chatroom__info__customer,
  .chat-info,
  .chart__header .description,
  .task__option__item,
  .task__option__item .material-icons,
  .mat-cell,
  .mat-footer-cell,
  .mat-header-cell,
  .form__field label,
  .form__field,
  .sidebar__user__info,
  .sidebar__collapse .icon-button,
  .sidebar__user .icon-button,
  .dialog__action .text-button,
  .privacy {
    color: $white;
  }

  .tabs a {
    color: $white !important;
  }

  .knowledge__item__content label {
    color: $grey;
    font-weight: bold;
  }

  .mat-tab-link,
  .mat-tab-label,
  .mat-tab-label-active {
    color: $primary-light !important;
  }

  .tabs,
  .dialog__action,
  .form__row {
    border-color: $black-dark;
  }

  .channel-item,
  .channel-header {
    border-color: $black-dark !important;
  }

  .mat-checkbox-label,
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: $white !important;
  }

  textarea,
  input,
  .samples__item,
  .form__field input,
  .chatroom__input textarea:focus {
    background-color: $black-light;
    color: $white;
  }

  textarea,
  input,
  .form__field input {
    &:disabled,
    &:read-only {
      background-color: transparent;
    }
  }

  .auth__form__row input,
  .chatroom__input input,
  .chatroom__input textarea {
    background-color: transparent;
  }

  .mat-paginator-icon {
    fill: $grey;
  }

  .mat-paginator-page-size .mat-paginator-range-actions .mat-icon-button,
  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    color: $grey;
  }

  .mat-dialog-container,
  .mat-menu-panel,
  a.mat-menu-item,
  button.mat-menu-item {
    background-color: $black;
    color: $white;
  }

  a.mat-menu-item:hover:not([disabled]),
  button.mat-menu-item:hover:not([disabled]) {
    background-color: $black-light;
  }

  .task__title.builtin {
    background-color: $black-darker;
  }

  .task__option__item {
    border-color: $black-dark;
  }

  .mat-slider-disabled {
    .mat-slider-thumb,
    .mat-slider-thumb-label,
    .mat-slider-track-fill {
      background-color: $black-light !important;
    }

    .mat-slider-track-background {
      background-color: $black-light !important;
    }
  }

  .setting__user__info .icon-button,
  .form__action .icon-button {
    color: $black-light;
  }

  .knowledge__item {
    border-color: $black;
  }

  // .page__header {
  //   background-image: $gradient-dark;
  // }

  // .channel-details-title h2,
  // .channel-description h1,
  // .form__section {
  //   color: $blue;
  // }

  .form__section__description,
  .form__label {
    color: $primary-light;
  }

  #friday-code-snippet {
    background-color: $black-light !important;
    color: $white;
  }

  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"])
    .mat-expansion-panel-header:hover {
    background-color: $black !important;
  }

  .auth__form__row {
    background-color: $black;
    color: $white;
    border-color: $black;

    label,
    input {
      color: $white;
    }

    &:focus-within {
      border-color: $primary;
    }
  }

  .sidebar__logo img,
  .auth__feature .logo img {
    filter: brightness(1.5);
  }

  .fb-page__title {
    color: $grey !important;
  }

  .channel-description ul {
    color: $white !important;
  }

  .attachment__item,
  .flat,
  .sidebar__menu__item {
    &:hover:not(:disabled) {
      background-color: $black-light;
    }
  }

  .bg-black {
    background-color: $black-dark !important;
  }

  label.checked {
    color: $primary-light !important;
  }

  .emoji-mart,
  .emoji-mart-category-label span {
    background-color: $black-dark;
    color: $white;
  }

  ::placeholder {
    color: $white !important;
  }

  .onboarding {
    background-color: $primary;

    @include respond-to(phone) {
      background-color: $black;
      background-image: none;
    }

    .onboarding__content p {
      color: $white;
    }

    .onboarding__wrapper {
      background-color: $black;

      .poll__question__label,
      .text-button {
        color: $white;
      }

      .radio-button label {
        color: $primary-light;
      }

      .radio-button label.checked {
        color: $white;
      }

      .poll__question .text-button {
        background-color: $black-dark !important;
      }

      .poll__question input {
        background-color: transparent;
        color: $white;
      }
    }
  }
}
