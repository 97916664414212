@import "responsive";

.container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.center {
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}

[hidden],
.hidden {
  display: none !important;
  visibility: hidden !important;
}

.invisible {
  opacity: 0 !important;
}

.no-select {
  user-select: none;
}

.left {
  @extend .row;
  justify-content: flex-start;
  flex-grow: 1;

  a,
  button {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
}

.right {
  @extend .row;
  justify-content: flex-end;
  flex-shrink: 0;

  a,
  button {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  a,
  button:last-child {
    margin-right: 0 !important;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}

.scrollable {
  width: 100%;
  min-height: 0;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // -webkit-overflow-scrolling: touch;
}

.round-corner {
  border-radius: $border-radius;
}

.circle {
  border-radius: 50%;
}

.tabs {
  @extend .row;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 1px solid $primary-light;

  a {
    font-size: 1.1rem;
    padding: 1rem 2rem;
    margin-right: 1rem;
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $font-color;
    min-width: 8rem;
    border-radius: 0;
    border-bottom: 2px solid transparent;

    @include respond-to(phone) {
      max-width: 8rem;
      font-size: 1.2rem;
      min-width: 5rem;
    }

    span {
      @extend .ellipsis;
      margin-left: 0.5rem;

      @include respond-to(phone) {
        display: none;
      }

      &:only-child {
        margin-left: 0;

        @include respond-to(phone) {
          display: block;
        }
      }
    }

    .material-icons {
      font-size: 1.2em;

      @include respond-to(phone) {
        font-size: 1.7em;
      }
    }

    img {
      width: 1em;

      @include respond-to(phone) {
        width: 1.5em;
      }
    }

    &.selected {
      border-color: $primary;
    }
  }
}

.tab-content {
  @extend .scrollable;
  width: 100%;
  padding-right: 0.5rem;
}

.draggable-list {
  @extend .scrollable;
  display: block;
  min-height: 1px;
}

.draggable-item {
  position: relative;

  .drag-handle {
    @extend .row;
    justify-content: center;
    width: 1rem;
    position: absolute;
    left: 1rem;
    cursor: move;

    .material-icons {
      color: #cccccc;
      font-size: 1.3rem;
    }
  }
}

.data-table {
  @extend .scrollable;
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.footer {
  @extend .row;
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  justify-content: flex-end;

  img {
    height: 1.5rem;
    margin-left: 0.5rem;
  }
}
