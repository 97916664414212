#app-nav-top,
#app-nav-bottom {
  display: none;
  z-index: 10;
  position: absolute;

  @include respond-to(phone) {
    display: flex;
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
}

#app-nav-top {
  top: 0;
  background-color: transparent;
  justify-content: flex-end;
  right: 0;

  .navbar {
    justify-content: flex-end;
    padding: 1.5rem 2rem;

    a {
      color: white;
      margin-left: 2rem;
      padding: 0;

      .material-icons {
        font-size: 2rem;
      }
    }
  }
}

#app-nav-bottom {
  bottom: 0;
  box-shadow: $box-shadow-dark;
  width: 100%;
  // height: 5rem;
  background-color: white;
  z-index: 30;

  .navbar {
    justify-content: space-around;

    @at-root #{&}__logo {
      padding: 1rem;

      img {
        width: 3rem;
      }
    }

    a {
      color: $grey;
    }

    a.active {
      color: $primary;
    }
  }
}
