$black: #2f2f2f;
$blue: #2a7dd2;
$purple: #6b48af;
$green: #40e0d0;
$royal-blue: #4169e1;
$classic-blue: #004680;
$navy-blue: #38509c;
$midnight-blue: #191970;
$turquoise: #4de4e1;

$primary: $navy-blue;
$primary-200: #334b96;
$primary-300: #2b4289;
$primary-light: #efeef8;
$primary-dark: #1a1a1a;

$success: $green;
$success-300: #009999;
$success-light: rgba(12, 197, 183, 0.3);

$error: #ff1b69;
$error-light: rgb(243, 78, 78, 0.3);

$warn: #ffca00;
$warn-light: #ffe688;
$warn-dark: #927300;

// General Color
$white: #ffffff;
$white50: #ffffff50;
$white80: #ffffff80;
$black-dark: #282828;
$black-light: #606060;
$black-darker: #1a1a1a;
$grey: #808080; //#cccccc;#82889a
$grey50: #80808050;
$grey80: #80808080;
$grey-dark: #555555;
$grey-light: rgba(0, 0, 0, 0.04); //#EFF0F4;#
// $blue:#3351AF;

$light: linear-gradient(
  0,
  rgba(255, 255, 255, 0.3),
  rgba(255, 255, 255, 0.527)
);
$dark: linear-gradient(0, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

// $gradient-theme: linear-gradient(120deg, #00b7a9 -10%, #6b48af 50%, #2a7dd2 120%);
$gradient-theme: radial-gradient(
  circle farthest-corner at 12.3% 19.3%,
  $primary 15%,
  $turquoise 100%
);
$gradient-light: radial-gradient(
  circle farthest-corner at 0% 20%,
  rgba(255, 255, 255, 0),
  rgba(255, 255, 255, 0.3)
);
$gradient-dark: radial-gradient(
  circle farthest-corner at 0% 20%,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0.3)
);
$gradient: linear-gradient(145deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
  linear-gradient(145deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3));

$font-color: #3a3a3a;
$font-grey: #b7b7b7;
$border-radius: 2px;
$box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
$box-shadow-dark: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
$box-shadow-light: 0px 2px 6px 0px rgba(255, 255, 255, 0.4);
